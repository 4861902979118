// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-black-friday-tsx": () => import("./../../../src/pages/blog/black-friday.tsx" /* webpackChunkName: "component---src-pages-blog-black-friday-tsx" */),
  "component---src-pages-claim-[token]-tsx": () => import("./../../../src/pages/claim/[token].tsx" /* webpackChunkName: "component---src-pages-claim-[token]-tsx" */),
  "component---src-pages-contact-success-tsx": () => import("./../../../src/pages/contact-success.tsx" /* webpackChunkName: "component---src-pages-contact-success-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pay-cancel-tsx": () => import("./../../../src/pages/pay-cancel.tsx" /* webpackChunkName: "component---src-pages-pay-cancel-tsx" */),
  "component---src-pages-pay-failure-tsx": () => import("./../../../src/pages/pay-failure.tsx" /* webpackChunkName: "component---src-pages-pay-failure-tsx" */),
  "component---src-pages-pay-peach-tsx": () => import("./../../../src/pages/pay/peach.tsx" /* webpackChunkName: "component---src-pages-pay-peach-tsx" */),
  "component---src-pages-pay-success-tsx": () => import("./../../../src/pages/pay-success.tsx" /* webpackChunkName: "component---src-pages-pay-success-tsx" */),
  "component---src-pages-pay-tsx": () => import("./../../../src/pages/pay.tsx" /* webpackChunkName: "component---src-pages-pay-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

